import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { BaseTaskAttributes, MessageType, RVTask, TaskAttributes } from "@regal-voice/shared-types";
import { noop } from "lodash";
import { useSelector } from "react-redux";

import {
    PreloaderBaseMetadata,
    PreloadedAudioPlayer,
    PreloaderMetadata,
} from "Components/shared/PreloadedAudioPlayer/PreloadedAudioPlayer";
import { useServerSentEvent } from "Hooks/useServerSentEvent";
import { getLogger } from "Services/LoggingService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";
import { selectBrandAppNotificationsConfigList } from "Services/state/brand";
import { ReservationCreatedSSETaskData } from "Services/state/tasks/SSESubscription";
import { mapReservationCreatedSSEToRVTask } from "Services/state/tasks/Utils";
import { NormalizedAudioNotificationConfig } from "Types/Brand";

const logger = getLogger("Revamped Audio Notifications");
type TaskNotificationMetadata = PreloaderBaseMetadata<TaskMetadata>;
type TaskMetadata = {
    taskTitle: NormalizedAudioNotificationConfig["taskTitle"];
    triggeringEvent: NormalizedAudioNotificationConfig["triggeringEvent"];
    /**
     * If true, the audio should finish playing even if the user is on an active call.
     * This is useful for tasks that are auto-answered, so the audio doesn't get cut off.
     * This is false by default.
     */
    isAutoAnswer: boolean;
    /**
     * An optional attribute to check within the Task's attributes to determine if the audio should be played.
     */
    attribute?: string;
};

type PlayTaskAudioFn = (
    triggeringEvent: NormalizedAudioNotificationConfig["triggeringEvent"],
    taskAttributes: Partial<TaskAttributes>,
    resSid: string,
    taskSid: string
) => void;

export type AudioNotificationContextValue = {
    playTaskAudio: PlayTaskAudioFn;
    registerAudioPlayer(metadata: TaskNotificationMetadata): () => void;
};

function getAudioPlayerKey(metadata: {
    taskTitle: NormalizedAudioNotificationConfig["taskTitle"];
    triggeringEvent: NormalizedAudioNotificationConfig["triggeringEvent"];
}): string {
    return `${metadata.triggeringEvent}-${metadata.taskTitle}`;
}

export const AudioNotificationContext = createContext<AudioNotificationContextValue>({
    playTaskAudio: noop,
    registerAudioPlayer: () => noop,
});

/**
 * We use this threshold for two things:
 * 1. The same exact reservation not triggering different audio notifications in quick succession.
 * 2. The same exact audio notiifcation (regardless of what is invoking it) not playing in quick succession.
 */
export const __msToWaitForSameEventNotifications = 1000;

const notifiedReservations: Record<string, number> = {};

export function __clearNotifiedReservationsForTests() {
    for (const prop of Object.getOwnPropertyNames(notifiedReservations)) {
        delete notifiedReservations[prop];
    }
}

/**
 * Checks if this task is the second leg of a call task that we don't want to notify about.
 * These are our ASAP Callback, Scheduled Callback and Preview Dial (Outbound Call) workflows.
 */
function isSecondLegCallTask(attributes: Partial<TaskAttributes>): boolean {
    // types suck
    const { originalTaskQueueName, original_task_sid } = attributes as Partial<TaskAttributes> & {
        original_task_sid: string;
        originalTaskQueueName: string;
    };
    return ["Outbound Call", "Callbacks"].includes(originalTaskQueueName) && !!original_task_sid;
}

/**
 * We should only notify if the given reservation hasn't already been notified in the last second.
 * We should only notifiy if the user is not on an active call, unless the audio is configured to playthrough regardless.
 * We should NOT notify on tasks that are the second leg of a call task like ASAP Callback, Scheduled Callback or Preview Dial (Outbound Call).
 */
function shouldTaskNotificationBePlayed(
    audioPlayer: TaskNotificationMetadata,
    resSid: string,
    onActiveCall: boolean,
    taskAttributes: Partial<TaskAttributes>
): boolean {
    if (isSecondLegCallTask(taskAttributes)) {
        return false;
    }
    if (audioPlayer.isAutoAnswer !== !!taskAttributes.autoAnswer) {
        return false;
    }
    if (onActiveCall && !audioPlayer.isAutoAnswer) {
        return false;
    }
    const lastNotifiedTimestamp = notifiedReservations[resSid];
    if (!lastNotifiedTimestamp) {
        return true;
    }
    const msSinceLastNotified = Date.now() - lastNotifiedTimestamp;
    return msSinceLastNotified > __msToWaitForSameEventNotifications;
}

function markReservationAsNotified(resSid: string): void {
    notifiedReservations[resSid] = Date.now();
}

/**
 * Preemptively loads all of the brand's audio notifications.
 * It renders a player for each audio notification which registers itself using the context.
 * It plays the notifications when the appropriate SSEs occur, and stops them when the reservation is no longer relevant.
 * It makes sure to only play audios if the user is not on a call and the reservation hasn't already been notified in the last second.
 */
export function AudioNotificationProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
    const [audioPlayers, setAudioPlayers] = useState<Record<string, TaskNotificationMetadata>>({});
    const audiosList = useSelector(selectBrandAppNotificationsConfigList);
    const onActiveCall = useSelector(selectOnActiveCall);

    const playTaskAudio = useCallback(
        (
            triggeringEvent: NormalizedAudioNotificationConfig["triggeringEvent"],
            taskAttributes: Partial<TaskAttributes>,
            resSid: string,
            taskSid: string
        ) => {
            const { title } = taskAttributes;
            const key = getAudioPlayerKey({ taskTitle: title as string, triggeringEvent });
            const audioPlayer = audioPlayers[key];
            if (audioPlayer && shouldTaskNotificationBePlayed(audioPlayer, resSid, onActiveCall, taskAttributes)) {
                logger.log("Trying to play audio", { triggeringEvent, taskTitle: title, resSid, taskSid });
                audioPlayer.play();
                markReservationAsNotified(resSid);
            }
        },
        [audioPlayers, onActiveCall]
    );

    const stopTaskAudio = useCallback(
        (
            triggeringEvent: NormalizedAudioNotificationConfig["triggeringEvent"],
            taskTitle: string,
            resSid: string,
            taskSid: string,
            checkForLoop: boolean = false
        ) => {
            const key = getAudioPlayerKey({ taskTitle, triggeringEvent });
            const audioPlayer = audioPlayers[key];
            if (audioPlayer) {
                logger.log("Trying to stop audio", { triggeringEvent, taskTitle, resSid, checkForLoop });
                audioPlayer.stop(checkForLoop);
            }
        },
        [audioPlayers]
    );

    const registerAudioPlayer = useCallback((metadata: TaskNotificationMetadata) => {
        const key = getAudioPlayerKey(metadata);
        setAudioPlayers((prev) => ({ ...prev, [key]: metadata }));
        return () => {
            setAudioPlayers((prev) => {
                const newState = { ...prev };
                delete newState[key];
                return newState;
            });
        };
    }, []);

    /**
     * If there's an associated audio notification config, we'll play it
     * If there was an in-progress audio notification for the same task, we'll stop it
     */
    const handleReservationAccepted = useCallback(
        (data: MessageType["twilio"]["reservation.accepted"]) => {
            const { title } = data.task.attributes as BaseTaskAttributes;
            const rvTask = mapReservationCreatedSSEToRVTask(data.task as ReservationCreatedSSETaskData);
            stopTaskAudio("reservation.created", title, (data.task as RVTask).sid, (data.task as RVTask).taskSid, true);
            playTaskAudio(
                "reservation.accepted",
                rvTask.attributes as Partial<TaskAttributes>,
                (data.task as RVTask).sid,
                (data.task as RVTask).taskSid
            );
        },
        [playTaskAudio, stopTaskAudio]
    );

    /**
     * If there is an associated audio notification config, we'll play it
     * We map the attributes with the utility mainly to make sure we have
     * autoAnswer set up according to team-based preferences, which are resolved in UI
     */
    const handleReservationCreated = useCallback(
        (data: MessageType["twilio"]["reservation.created"]) => {
            const rvTask = mapReservationCreatedSSEToRVTask(data.task as ReservationCreatedSSETaskData);
            playTaskAudio(
                "reservation.created",
                rvTask.attributes as Partial<TaskAttributes>,
                (data.task as RVTask).sid,
                (data.task as RVTask).taskSid
            );
        },
        [playTaskAudio]
    );

    /**
     * When the reservation is no longer relevant, we stop any potentially in-progress audio
     * We map the attributes with the utility mainly to make sure we have
     * autoAnswer set up according to team-based preferences, which are resolved in UI
     */
    const handleReservationBeingGone = useCallback(
        (data: MessageType["twilio"]["reservation.canceled"]) => {
            const { title } = data.task.attributes as BaseTaskAttributes;
            stopTaskAudio("reservation.created", title, (data.task as RVTask).sid, (data.task as RVTask).taskSid);
            stopTaskAudio("reservation.accepted", title, (data.task as RVTask).sid, (data.task as RVTask).taskSid);
        },
        [stopTaskAudio]
    );

    useServerSentEvent("twilio", "reservation.accepted", handleReservationAccepted);
    useServerSentEvent("twilio", "reservation.created", handleReservationCreated);

    // handle all cases where a reservation is no longer relevant
    useServerSentEvent("twilio", "reservation.canceled", handleReservationBeingGone);
    useServerSentEvent("twilio", "reservation.completed", handleReservationBeingGone);
    useServerSentEvent("twilio", "reservation.rejected", handleReservationBeingGone);
    useServerSentEvent("twilio", "reservation.rescinded", handleReservationBeingGone);
    useServerSentEvent("twilio", "reservation.timeout", handleReservationBeingGone);

    useEffect(() => {
        // only works for when a call gets connected on the current tab
        if (onActiveCall) {
            audiosList.forEach(({ triggeringEvent, taskTitle, attribute }) => {
                const isAutoAnswer = attribute === "autoAnswer";
                if (!isAutoAnswer) {
                    stopTaskAudio(triggeringEvent, taskTitle, "", "", true);
                }
            });
        }
    }, [onActiveCall, audiosList, stopTaskAudio]);

    const preparePlayerMetadata = useMemo(() => {
        return audiosList.reduce(
            (
                acc: Record<string, PreloaderMetadata<TaskMetadata>>,
                { url, loop, attribute, taskTitle, triggeringEvent }
            ) => {
                const key = getAudioPlayerKey({ taskTitle, triggeringEvent });
                acc[key] = {
                    audioProps: {
                        src: url,
                        loop: !!loop,
                    },
                    extraProps: {
                        attribute,
                        triggeringEvent,
                        taskTitle,
                        isAutoAnswer: attribute === "autoAnswer",
                    },
                };
                return acc;
            },
            {}
        );
    }, [audiosList]);

    return (
        <AudioNotificationContext.Provider value={{ playTaskAudio, registerAudioPlayer }}>
            <>
                {audiosList.map(({ taskTitle, triggeringEvent }) => {
                    const key = getAudioPlayerKey({ taskTitle, triggeringEvent });
                    const audioProps = preparePlayerMetadata[key].audioProps;
                    const extraProps = preparePlayerMetadata[key].extraProps;
                    return (
                        <PreloadedAudioPlayer
                            key={key}
                            msToWaitForSameEventNotifications={__msToWaitForSameEventNotifications}
                            registerAudioPlayer={registerAudioPlayer}
                            audioProps={audioProps}
                            extraProps={extraProps}
                            withCacheBust
                        />
                    );
                })}
            </>
            {children}
        </AudioNotificationContext.Provider>
    );
}

export const useAudioNotifications = (): AudioNotificationContextValue => useContext(AudioNotificationContext);
