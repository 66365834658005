import { useEffect, useMemo } from "react";

import { cloneDeep, debounce } from "lodash";
import { useSelector } from "react-redux";

import { useVisualNotification } from "Components/elements/UserNotifier/hooks/useVisualNotification";
import { getLogger } from "Services/LoggingService";
import { selectReservationSidStatusKey, selectTasks } from "Services/state/tasks/Selectors";
import { TasksState } from "Services/state/tasks/TasksStateSlice";
import { PromiseChain } from "Utils/PromiseChain";

const logger = getLogger("useNotifications");

const adjustNotificationsQueue = new PromiseChain<void, typeof adjustNotificationsToNewTaskState>("Notifications");

export function useNotifications(): void {
    const tasksState = useSelector(selectTasks);
    const { showVisualNotification } = useVisualNotification();
    const reservationSidStatusKey = useSelector(selectReservationSidStatusKey);

    /**
     * Changes to the reservationSidStatusKey can happen in bursts, often with the same task switching back and forth between statuses.
     * Since stopping is sync and playing is async, this can cause race conditions.
     * We should wait for that dust to settle before doing anything.
     */
    const handleChangeInReservationStatuses = useMemo(
        () =>
            debounce(
                () => {
                    adjustNotificationsQueue.addJobToQueue(() =>
                        adjustNotificationsToNewTaskState(tasksState, showVisualNotification)
                    );
                },
                250,
                { trailing: true }
            ),
        [showVisualNotification, tasksState]
    );

    useEffect(() => {
        handleChangeInReservationStatuses();
    }, [handleChangeInReservationStatuses, reservationSidStatusKey]);
}

let currentPendingTasks: TasksState = {};

export async function adjustNotificationsToNewTaskState(
    tasksState: TasksState,
    showVisualNotificationFn: (text: string, taskSid: string) => void
): Promise<void> {
    const newPendingTasks = getPendingTasks(tasksState);
    const addedTasks = diffTaskStates(currentPendingTasks, newPendingTasks);

    if (Object.keys(addedTasks).length) {
        startNotifications(addedTasks, showVisualNotificationFn);
    }

    currentPendingTasks = newPendingTasks;
}

export function getPendingTasks(taskState: TasksState) {
    const newPendingTasks: TasksState = {};
    Object.entries(taskState).forEach(([resSid, task]) => {
        const isNotSecondPowerDialTask = !(
            task.taskChannelUniqueName !== "default" &&
            task.taskChannelUniqueName !== "regal" &&
            task.attributes.autoAnswer === true
        );
        // TODO: Is there ever a pending auto dial task?
        if (task.status === "pending" && isNotSecondPowerDialTask) {
            newPendingTasks[resSid] = cloneDeep(task);
        }
    });
    logger.debug("useNotifications - New pending tasks", { newPendingTasks });
    return newPendingTasks;
}

export function diffTaskStates(oldTaskState: TasksState, newTaskState: TasksState): TasksState {
    const combinedTaskStates = { ...oldTaskState, ...newTaskState };
    const addedTasks: TasksState = {};

    for (const resSid in combinedTaskStates) {
        const task = combinedTaskStates[resSid];

        if (!oldTaskState[resSid] && !!newTaskState[resSid]) {
            addedTasks[resSid] = task;
        }
    }

    logger.debug("useNotifications - Diff in task states", {
        combinedTaskStates: Object.keys(combinedTaskStates),
        added: Object.keys(addedTasks),
    });
    return addedTasks;
}

export function startNotifications(
    taskStateAdditions: TasksState,
    showVisualNotificationFn: (text: string, taskSid: string) => void
): void {
    for (const resSid in taskStateAdditions) {
        const task = taskStateAdditions[resSid];
        const taskTitle = task.attributes?.title;

        // useVisualNotification determines when to *actually* show the notification or not
        // it should only be when the agent is not on the agent desktop page
        showVisualNotificationFn(`You have a new ${taskTitle} task`, task.taskSid);
    }
}

/**
 * ONLY to be used for testing purposes
 */
export function __resetCurrentPendingTasks(): void {
    currentPendingTasks = {};
}
